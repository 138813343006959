import _slicedToArray from "@babel/runtime-corejs3/helpers/esm/slicedToArray";
import { useState, useRef, useCallback } from 'react';
var COLLAPSE_STATES;

(function (COLLAPSE_STATES) {
  COLLAPSE_STATES["OPENING"] = "OPENING";
  COLLAPSE_STATES["OPEN"] = "OPEN";
  COLLAPSE_STATES["CLOSING"] = "CLOSING";
  COLLAPSE_STATES["CLOSE"] = "CLOSE";
})(COLLAPSE_STATES || (COLLAPSE_STATES = {})); // eslint-disable-next-line complexity


function useCollapseState(_ref) {
  var shouldBeOpened = _ref.shouldBeOpened,
      cancelAnimation = _ref.cancelAnimation;

  var _useState = useState(false),
      _useState2 = _slicedToArray(_useState, 2),
      setDummyState = _useState2[1];

  var stateRef = useRef(COLLAPSE_STATES.CLOSE);
  var onAnimationEnd = useCallback(function () {
    if ((stateRef.current === COLLAPSE_STATES.CLOSING || stateRef.current === COLLAPSE_STATES.OPENING) && !shouldBeOpened) {
      stateRef.current = COLLAPSE_STATES.CLOSE; //force rerender

      setDummyState(function (s) {
        return !s;
      });
    } else if (stateRef.current === COLLAPSE_STATES.OPENING && shouldBeOpened) {
      stateRef.current = COLLAPSE_STATES.OPEN; //force rerender

      setDummyState(function (s) {
        return !s;
      });
    }
  }, [shouldBeOpened]);

  if (shouldBeOpened && stateRef.current !== COLLAPSE_STATES.OPEN) {
    stateRef.current = cancelAnimation ? COLLAPSE_STATES.OPEN : COLLAPSE_STATES.OPENING;
  } else if ((stateRef.current === COLLAPSE_STATES.OPEN || stateRef.current === COLLAPSE_STATES.OPENING) && !shouldBeOpened) {
    stateRef.current = cancelAnimation ? COLLAPSE_STATES.CLOSE : COLLAPSE_STATES.CLOSING;
  }

  return {
    onAnimationEnd: onAnimationEnd,
    state: stateRef.current
  };
}

function useStartedClosing(state) {
  var isStartedClosingRef = useRef(false);
  var prevState = useRef(state);
  var contentRef = useRef(null);

  var _useState3 = useState(false),
      _useState4 = _slicedToArray(_useState3, 2),
      setDummyState = _useState4[1];

  if (prevState.current === COLLAPSE_STATES.OPEN && state === COLLAPSE_STATES.CLOSING) {
    isStartedClosingRef.current = true; // the next frame we need to force layout recalculations and new render

    if (typeof window !== 'undefined') {
      requestAnimationFrame(function () {
        if (!contentRef.current || !isStartedClosingRef.current) {
          return;
        } // force layout recalc, without this step firefox doesn't register style values for transitions
        // eslint-disable-next-line no-unused-expressions


        contentRef.current.offsetHeight;
        isStartedClosingRef.current = false;
        setDummyState(function (s) {
          return !s;
        });
      });
    }
  }

  prevState.current = state;
  return {
    isStartedClosing: isStartedClosingRef.current,
    contentRef: contentRef
  };
}

export { COLLAPSE_STATES, useCollapseState, useStartedClosing };
