import "./bookList.css";
import "@rescui/typography/lib/font-jb-sans-auto.css";

import { Button } from "@rescui/button";
import { DropdownMenu, MenuItem } from "@rescui/dropdown-menu";
import { DownIcon } from "@rescui/icons";
import { Tab, TabList, TabSeparator } from "@rescui/tab-list";
import i18next from "i18next";
import sortBy from "lodash/sortBy";
import React, { Component } from "react";

import i18nextPromise from "../../i18next.js";
import Footer from "../../layout/Footer";
import Header from "../../layout/Header";
import Layout from "../../layout/Layout";
import Main from "../../layout/Main";
import {
  ErrorAwareState,
  fetchBatchOrLogin,
  fetchJsonOrLogin,
  fetchOrLogin,
} from "../../Util/common";
import LibraryBookSection from "./LibraryBookSection";
import {
  Library,
  LibraryID,
  User,
  UserSettings,
  UserWithCountCallbacks,
  UserWithTakenCount,
} from "./model";
import { UserBookSection } from "./UserBookSection";
import { UserChooser } from "./UserChooser";

interface State extends ErrorAwareState {
  libraries: Library[];
  users: Map<number, UserWithTakenCount>;
  selectedLibrary: LibraryID;
  user: User | null;
  selectedUser: UserWithTakenCount | null;
  libChooserOpen: boolean;
  addingBook: boolean;
  active_tab: string;
}

export default class BookList
  extends Component<any, State>
  implements UserWithCountCallbacks
{
  state: State = {
    libraries: [],
    users: new Map(),
    selectedLibrary: -1,
    libChooserOpen: false,
    user: null,
    selectedUser: null,
    addingBook: false,
    active_tab: "library",
  };

  componentDidMount() {
    document.title = "JetID book list";

    const libRequest = fetchJsonOrLogin(
      this,
      process.env.REACT_APP_BOOK_API_URL + "/rest/library",
      "GET"
    );
    const userListRequest = fetchBatchOrLogin(
      this,
      process.env.REACT_APP_BOOK_API_URL + "/view/users_with_taken_count",
      "GET",
      (user: User) => user.UserID,
      1000
    );
    const userRequest = fetchJsonOrLogin(
      this,
      process.env.REACT_APP_BOOK_API_URL + "/rest/me",
      "GET"
    );

    Promise.all([
      libRequest,
      userListRequest,
      userRequest,
      i18nextPromise,
    ]).then((res) => {
      const libs = res[0] as Library[];
      const user = res[2] as User;
      const users = new Map<number, UserWithTakenCount>();
      (res[1] as UserWithTakenCount[]).forEach((user) =>
        users.set(user.UserID, user)
      );
      const userWithTakenCount = users.get(user.UserID)!;

      const chosenLibrary =
        user.Settings.selected_library_id ||
        user.DefaultLibraryID ||
        libs[1].LibraryID;
      this.setState({
        libraries: sortBy(libs, (lib: Library) => lib.LibraryName),
        selectedLibrary: chosenLibrary,
        users: users,
        user: user,
        selectedUser: userWithTakenCount,
      });
    });
  }

  private readonly select_user = (new_value: UserWithTakenCount | null) => {
    this.setState({ selectedUser: new_value });
  };

  render() {
    if (this.state.loadingError) return <div>{this.state.loadingError}</div>;

    const user = this.state.user;
    if (
      !user ||
      this.state.libraries.length === 0 ||
      this.state.users.size === 0
    )
      return <div>Loading...</div>;

    if (!user.UserName) {
      return (
        <div>
          {i18next.t("Performing first-time setup for {{email}}...", {
            email: user.UserEmail,
          })}
        </div>
      );
    }

    const title = i18next.t("Welcome to JetID Book, {{user}}!", {
      user: user.UserName,
    });

    const selectedLibrary = this.state.libraries.find(
      (l) => l.LibraryID === this.state.selectedLibrary
    ) as Library;

    if (user.Settings.welcome_message_shown !== true) {
      const welcomeMessage =
        selectedLibrary.WelcomeMessage ||
        `<div>Welcome to the JetBrains ${selectedLibrary.LibraryName} library of printed books!</div>`;

      return (
        <Layout>
          <Header title={title} />
          <Main>
            <div className="book-list">
              <div dangerouslySetInnerHTML={{ __html: welcomeMessage }} />
              <Button
                size="l"
                mode="rock"
                onClick={() =>
                  this.update_settings(user, { welcome_message_shown: true })
                }
              >
                Got it!
              </Button>
            </div>
          </Main>
        </Layout>
      );
    }

    const pageState = {
      user: this.state.users.get(user.UserID)!,
      allUsers: this.state.users,
      library: selectedLibrary,
      selectedUser: this.state.selectedUser!,
    };

    const isMe = this.state.selectedUser?.UserID === this.state.user?.UserID;

    const userName = this.state.selectedUser?.UserName;
    const bookTakenCount = this.state.selectedUser?.TakenCount || 0;
    const bookText = isMe
      ? i18next.t("My books ({{count}})", { count: bookTakenCount })
      : i18next.t("Books of {{name}} ({{count}})", {
          name: userName,
          count: bookTakenCount,
        });

    const bookWaitingListCount = this.state.selectedUser?.WaitingCount || 0;
    const waitingText = isMe
      ? i18next.t("My waiting list ({{count}})", {
          count: bookWaitingListCount,
        })
      : i18next.t("Waiting list of {{name}} ({{count}})", {
          name: userName,
          count: bookWaitingListCount,
        });

    return (
      <Layout>
        <Header title={title}>
          <DropdownMenu
            isOpen={this.state.libChooserOpen}
            onRequestClose={() => this.setState({ libChooserOpen: false })}
            trigger={
              <Button
                size="m"
                icon={<DownIcon />}
                iconPosition="right"
                mode="outline"
                onClick={() =>
                  this.setState({
                    libChooserOpen: !this.state.libChooserOpen,
                  })
                }
              >
                {selectedLibrary?.LibraryName || "?"}
              </Button>
            }
          >
            {this.state.libraries.map((lib) => (
              <MenuItem
                size="m"
                key={lib.LibraryID.toString()}
                mode="rock"
                onClick={() => {
                  this.setState({
                    selectedLibrary: lib.LibraryID,
                    libChooserOpen: false,
                  });
                  this.update_settings(user, {
                    selected_library_id: lib.LibraryID,
                  });
                }}
              >
                {lib.LibraryName}
              </MenuItem>
            ))}
          </DropdownMenu>
        </Header>

        <Main>
          {this.state.user?.UserRole === "admin" && (
            <div className="rs-text-2 mb-8">
              {i18next.t("Change User")}
              <UserChooser
                allUsers={pageState.allUsers}
                selected={pageState.selectedUser}
                renderItem={(user) => {
                  const count = user?.TakenCount;
                  return (user?.UserName || "?") + " (" + (count || 0) + ")";
                }}
                showNullUser={false}
                libraryId={selectedLibrary.LibraryID}
                onChosen={(user) => this.select_user(user)}
              />
            </div>
          )}
          <TabList
            size="l"
            short={false}
            mode="rock"
            value={this.state.active_tab}
            onChange={(new_tab) => this.setState({ active_tab: new_tab })}
          >
            <Tab value="library">{i18next.t("Library")}</Tab>
            {bookTakenCount === 0 &&
            this.state.active_tab !== "user_books" ? null : (
              <Tab value="user_books">{bookText}</Tab>
            )}
            {bookWaitingListCount === 0 ? null : (
              <Tab value="waiting_list">{waitingText}</Tab>
            )}
          </TabList>

          <TabSeparator />

          <div className="tab">
            {this.state.active_tab === "library" ? (
              <LibraryBookSection
                key={"library_" + selectedLibrary.LibraryID.toString()}
                page_state={pageState}
                callbacks={this}
              />
            ) : this.state.active_tab === "user_books" ? (
              <UserBookSection
                key={
                  "user_books_" +
                  selectedLibrary.LibraryID.toString() +
                  this.state.selectedUser!.UserID.toString()
                }
                page_state={pageState}
                callbacks={this}
                endpoint={"list_instances"}
                userParamName={"TakenBy"}
                type={"Books"}
              />
            ) : this.state.active_tab === "waiting_list" ? (
              <UserBookSection
                key={
                  "user_waiting_list_" +
                  selectedLibrary.LibraryID.toString() +
                  this.state.selectedUser!.UserID.toString()
                }
                page_state={pageState}
                callbacks={this}
                endpoint={"user_waiting_list_books"}
                userParamName={"UserID"}
                type={"WaitingList"}
              />
            ) : null}
          </div>
          {user.UserRole === "admin" ? (
            <Button
              size="s"
              mode="rock"
              onClick={() => {
                this.update_settings(user, { welcome_message_shown: false });
              }}
            >
              Show welcome message
            </Button>
          ) : (
            <div />
          )}
        </Main>
        <Footer />
      </Layout>
    );
  }

  onJoinWaitingList(user_id: number): void {
    const user = this.state.users.get(user_id);
    if (!user) return;
    const updatedUser = { ...user, WaitingCount: user.WaitingCount + 1 };
    const updatedUsers = this.state.users;
    updatedUsers.set(user_id, updatedUser);
    this.setState({ selectedUser: updatedUser });
    this.setState((prevState) => ({ users: updatedUsers }));
  }

  onLeaveWaitingList(user_id: number): void {
    const user = this.state.users.get(user_id);
    if (!user) return;
    const updatedUser = { ...user, WaitingCount: user.WaitingCount - 1 };
    const updatedUsers = this.state.users;
    updatedUsers.set(user_id, updatedUser);
    this.setState({ selectedUser: updatedUser });
    this.setState((prevState) => ({ users: updatedUsers }));
  }

  onReturn(user_id: number): void {
    const user = this.state.users.get(user_id);
    if (!user) return;
    const updatedUser = { ...user, TakenCount: user.TakenCount - 1 };
    const updatedUsers = this.state.users;
    updatedUsers.set(user_id, updatedUser);
    this.setState({ selectedUser: updatedUser });
    this.setState((prevState) => ({ users: updatedUsers }));
  }

  onTake(user_id: number): void {
    const user = this.state.users.get(user_id);
    if (!user) return;
    const updatedUser = { ...user, TakenCount: user.TakenCount + 1 };
    const updatedUsers = this.state.users;
    updatedUsers.set(user_id, updatedUser);
    this.setState({ selectedUser: updatedUser });
    this.setState((prevState) => ({ users: updatedUsers }));
  }

  update_settings(user: User, newSettings: Partial<UserSettings>) {
    const new_user = {
      ...user,
      Settings: { ...user.Settings, ...newSettings },
    } as User;
    const new_users = this.state.users;
    const new_user_with_taken_count = {
      ...new_users.get(user.UserID),
      new_user,
    } as UserWithTakenCount;
    new_users.set(user.UserID, new_user_with_taken_count);
    this.setState({ user: new_user, users: new_users });
    fetchOrLogin(
      this,
      process.env.REACT_APP_BOOK_API_URL + "/rest/user/" + user.UserID,
      "PUT",
      JSON.stringify({ item: new_user })
    );
  }
}
