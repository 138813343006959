import { useTextStyles } from "@rescui/typography";

export type Props = Record<string, never>;

function Footer() {
  const textCn = useTextStyles();

  return (
    <footer className="mb-8 flex justify-center">
      <p className={textCn("rs-text-3", { hardness: "pale" })}>
        Website deployed on {process.env.REACT_APP_DEPLOY_DATE}
      </p>
    </footer>
  );
}

export default Footer;
