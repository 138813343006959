import { ThemeProvider } from "@rescui/ui-contexts";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import Screen from "./Screen";
import useSystemTheme from "./shared/useSystemTheme";
import BookList from "./Views/BookList";
import FitnessUserList from "./Views/FitnessUserList";
import Landing from "./Views/Landing";
import Login from "./Views/Login";
import ReportList from "./Views/ReportList";
import Timesheet from "./Views/Timesheet";

function App() {
  const theme = useSystemTheme();

  return (
    <ThemeProvider theme={theme}>
      <Screen>
        <BrowserRouter>
          <Switch>
            <Route exact path="/login" component={Login} />
            <Route exact path="/" component={Landing} />
            <Route exact path="/fitness-tablet" component={FitnessUserList} />
            <Route exact path="/reports" component={ReportList} />
            <Route exact path="/timesheet" component={Timesheet} />
            <Route exact path="/book" component={BookList} />
          </Switch>
        </BrowserRouter>
      </Screen>
    </ThemeProvider>
  );
}

export default App;
