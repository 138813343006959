import type { LinkParams as LinkStyleParams } from "@rescui/typography";
import { useTextStyles } from "@rescui/typography";
import clsx from "clsx";
import type { LinkProps as BaseLinkProps } from "react-router-dom";
import { Link as BaseLink } from "react-router-dom";

export type Props = BaseLinkProps & LinkStyleParams;

function Link({ external, hardness, mode, ...props }: Props) {
  const textCn = useTextStyles();

  const className = clsx(
    textCn("rs-link", { external, hardness, mode }),
    props.className
  );

  return <BaseLink {...props} className={className} />;
}

export default Link;
