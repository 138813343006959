var styles = {
  "dropdown": "_dropdown_1bu2l6k_50",
  "triggerWrapper": "_triggerWrapper_1bu2l6k_55",
  "contentWrapper": "_contentWrapper_1bu2l6k_59",
  "fadeInBottomToTop": "_fadeInBottomToTop_1bu2l6k_1",
  "fadeInTopToBottom": "_fadeInTopToBottom_1bu2l6k_1",
  "fadeInLeftToRight": "_fadeInLeftToRight_1bu2l6k_1",
  "fadeInRightToLeft": "_fadeInRightToLeft_1bu2l6k_1",
  "wrapperHidden": "_wrapperHidden_1bu2l6k_85",
  "themeLight": "_themeLight_1bu2l6k_105",
  "modeRock": "_modeRock_1bu2l6k_110",
  "themeDark": "_themeDark_1bu2l6k_114"
};
export { styles as default };
