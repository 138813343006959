var styles = {
  "hasBorderBottom": "_hasBorderBottom_1ycflw8_14",
  "main": "_main_1ycflw8_18",
  "themeLight": "_themeLight_1ycflw8_21",
  "themeDark": "_themeDark_1ycflw8_27",
  "rock": "_rock_1ycflw8_33",
  "opening": "_opening_1ycflw8_33",
  "opened": "_opened_1ycflw8_34",
  "closing": "_closing_1ycflw8_35",
  "classic": "_classic_1ycflw8_49",
  "title": "_title_1ycflw8_106",
  "sizeS": "_sizeS_1ycflw8_111",
  "button": "_button_1ycflw8_111",
  "sizeM": "_sizeM_1ycflw8_114",
  "sizeL": "_sizeL_1ycflw8_117",
  "content": "_content_1ycflw8_122",
  "closed": "_closed_1ycflw8_129",
  "closingTransition": "_closingTransition_1ycflw8_138",
  "rs-reset-letter-spacing": "_rs-reset-letter-spacing_1ycflw8_1",
  "leftIcon": "_leftIcon_1ycflw8_156",
  "innerWrapper": "_innerWrapper_1ycflw8_155",
  "rightIcon": "_rightIcon_1ycflw8_160",
  "disableLeftPadding": "_disableLeftPadding_1ycflw8_161",
  "clear": "_clear_1ycflw8_233",
  "icon": "_icon_1ycflw8_281",
  "iconPlus": "_iconPlus_1ycflw8_327",
  "iconArrow": "_iconArrow_1ycflw8_332",
  "accordion": "_accordion_1ycflw8_338",
  "accordionGap0": "_accordionGap0_1ycflw8_342",
  "accordionGap8": "_accordionGap8_1ycflw8_345",
  "accordionGap12": "_accordionGap12_1ycflw8_348",
  "accordionGap16": "_accordionGap16_1ycflw8_351",
  "accordionGap24": "_accordionGap24_1ycflw8_354",
  "accordionGap32": "_accordionGap32_1ycflw8_357"
};
export { styles as default };
