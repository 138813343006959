var styles = {
  "errorMessage": "_errorMessage_14zyrwg_4",
  "sizeL": "_sizeL_14zyrwg_8",
  "label": "_label_14zyrwg_9",
  "note": "_note_14zyrwg_12",
  "sizeM": "_sizeM_14zyrwg_17",
  "sizeS": "_sizeS_14zyrwg_26",
  "themeLight": "_themeLight_14zyrwg_36",
  "themeDark": "_themeDark_14zyrwg_40",
  "rs-reset-letter-spacing": "_rs-reset-letter-spacing_14zyrwg_1",
  "bold": "_bold_14zyrwg_77"
};
export { styles as default };
