import { useTextStyles } from "@rescui/typography";
import type { ReactNode } from "react";

import Logo from "../images/jetid-icon.svg";

export type Props = { title?: string; children?: ReactNode };

function Header({ title, children }: Props) {
  const textCn = useTextStyles();

  return (
    <header className="mb-8">
      <div className="flex items-center justify-between gap-4">
        <div className="flex items-center gap-4">
          <a href="/">
            <Logo focusable={false} aria-hidden />
            <span className="sr-only">JetID Home</span>
          </a>
          <h1 className={textCn("rs-h2")}>{title}</h1>
        </div>
        {children}
      </div>
    </header>
  );
}

export default Header;
