import { Button } from "@rescui/button";
import { LoadingIcon } from "@rescui/icons";
import { createTextCn } from "@rescui/typography";
import i18next from "i18next";
import React, { Component } from "react";

import { ErrorAwareState, fetchOrLogin } from "../../Util/common";
import { LazyList, LazyListState } from "../../Util/lazylist";
import BookComponent from "./BookComponent";
import {
  BookEntry,
  BookInstanceWithStatus,
  BookWithInstances,
  EditCallbacks,
  Library,
  PageState,
  UserWithCountCallbacks,
  WaitingListEntry,
} from "./model";

interface Props {
  fetch: (
    last_loaded_id: number,
    batch_size: number
  ) => Promise<BookWithInstances[]>;
  loading?: boolean;
  filter_books: (bookWithInstances: BookWithInstances) => boolean;
  page_state: PageState;
  order_link: string | null;
  callbacks: UserWithCountCallbacks;
}

interface State extends ErrorAwareState, LazyListState<BookWithInstances> {
  items: BookWithInstances[];
  adding_book: boolean;
}

class BookComponentList
  extends Component<Props, State>
  implements EditCallbacks
{
  state = {
    items: new Array<BookWithInstances>(),
    loading: false,
    complete: false,
    adding_book: false,
  };

  private readonly list = new LazyList(
    this,
    this.props.fetch,
    25,
    (bwi) => bwi.Book.BookID
  );

  componentDidMount() {
    this.list.loadMore();
  }

  render() {
    const loading = this.props.loading || this.state.loading;
    const textCn = createTextCn("light");
    const cn = textCn("rs-link", { mode: "rock", external: true });
    const order_link =
      this.props.order_link || "https://youtrack.jetbrains.com";
    const order_link_message = i18next.t(
      "Didn't find what you were looking for? <br/> Use {{- YouTrack}} to order the book you need.",
      {
        YouTrack:
          '<a href="' +
          order_link +
          '" class="' +
          cn +
          '" target="_blank" rel="noreferrer noopener">YouTrack</a>',
      }
    );
    return (
      <>
        {this.state.items
          .filter((bwi) => this.props.filter_books(bwi))
          .map((bwi) => {
            const instances = bwi.Instances;
            if (instances.length !== 0)
              return (
                <BookComponent
                  key={bwi.Book.BookID}
                  book={bwi}
                  pageState={this.props.page_state}
                  instances={instances}
                  callbacks={this}
                />
              );
            else return null;
          })
          .filter((value) => value !== null)}

        {loading ? (
          <LoadingIcon mode="rock" />
        ) : this.state.complete ? (
          this.props.order_link !== null && (
            <div dangerouslySetInnerHTML={{ __html: order_link_message }} />
          )
        ) : (
          <Button mode="rock" onClick={this.list.loadMore} disabled={loading}>
            {i18next.t("Load more")}
          </Button>
        )}
      </>
    );
  }

  editBook(book: BookEntry, instances: BookInstanceWithStatus[]): void {
    const bwi = this.state.items.find((b) => b.Book.BookID === book.BookID);
    if (!bwi) return;

    // a futile attempt at object comparison
    if (JSON.stringify(book) !== JSON.stringify(bwi.Book)) {
      fetchOrLogin(
        this,
        process.env.REACT_APP_BOOK_API_URL + "/rest/book/" + book.BookID,
        "PUT",
        JSON.stringify({ item: book })
      );
    }

    instances.forEach((instance) => {
      if (instance.BookInstanceID === -1) {
        this.addInstance(bwi.Book.BookID, instance).then((id) => {
          if (id !== null) instance.BookInstanceID = id;
        });
      } else {
        this.checkInstanceChange(bwi, instance);
      }
    });

    this.updateBook({ ...bwi, Book: book, Instances: instances });
  }

  onJoinWaitingList(bwi: BookWithInstances, library: Library): void {
    const userId = this.props.page_state.user.UserID;
    this.props.callbacks.onJoinWaitingList(userId);
    const createRequest = fetchOrLogin(
      this,
      process.env.REACT_APP_BOOK_API_URL + "/rest/book_instance_waiting_list",
      "POST",
      JSON.stringify({
        item: {
          BookID: bwi.Book.BookID,
          UserID: userId,
          LibraryID: library.LibraryID,
        },
      })
    );

    createRequest
      .then((res) => {
        const id = BookComponentList.createdId(res);
        this.updateBook({
          ...bwi,
          WaitingList: bwi.WaitingList.concat([
            { UserID: userId, LibraryID: library.LibraryID, WaitingListID: id },
          ]),
        });
      })
      .catch((e) => {
        console.log(e);
        this.props.callbacks.onLeaveWaitingList(userId);
      });
  }

  onLeaveWaitingList(
    bwi: BookWithInstances,
    library: Library,
    waitingListEntry: WaitingListEntry
  ): void {
    const userId = this.props.page_state.user.UserID;
    const waitingListId = waitingListEntry.WaitingListID;
    this.props.callbacks.onLeaveWaitingList(userId);

    fetchOrLogin(
      this,
      process.env.REACT_APP_BOOK_API_URL +
        "/rest/book_instance_waiting_list/" +
        waitingListId,
      "DELETE"
    );

    this.updateBook({
      ...bwi,
      WaitingList: bwi.WaitingList.filter((e) => e.UserID !== userId),
    });
  }

  private addInstance(
    bookId: number,
    instance: BookInstanceWithStatus
  ): Promise<number | null> {
    const createRequest = fetchOrLogin(
      this,
      process.env.REACT_APP_BOOK_API_URL + "/rest/book_instance",
      "POST",
      JSON.stringify({ item: { ...instance, BookID: bookId } })
    );
    if (instance.TakenBy !== null) {
      this.props.callbacks.onTake(instance.TakenBy);
      return createRequest.then((res) => {
        const id = BookComponentList.createdId(res);
        if (id !== null)
          this.changeInstanceOwnerRemote({ ...instance, BookInstanceID: id });
        return id;
      });
    }
    return createRequest.then((res) => BookComponentList.createdId(res));
  }

  private static createdId(response: Response): number | null {
    const location = response.headers.get("location");
    if (!location) return null;

    const slash = location.lastIndexOf("/");
    if (slash < 0) return null;

    return parseInt(location.substring(slash + 1), 10);
  }

  private checkInstanceChange(
    bwi: BookWithInstances,
    instance: BookInstanceWithStatus
  ) {
    const prev = bwi.Instances.find(
      (i) => i.BookInstanceID === instance.BookInstanceID
    );
    if (!prev) return;

    if (
      prev.Label !== instance.Label ||
      prev.WrittenOffBy !== instance.WrittenOffBy
    ) {
      fetchOrLogin(
        this,
        process.env.REACT_APP_BOOK_API_URL +
          "/rest/book_instance/" +
          instance.BookInstanceID,
        "PUT",
        JSON.stringify({ item: { ...instance, BookID: bwi.Book.BookID } })
      );
    }

    if (prev.TakenBy !== instance.TakenBy) {
      if (prev.TakenBy !== null) this.props.callbacks.onReturn(prev.TakenBy);
      if (instance.TakenBy !== null)
        this.props.callbacks.onTake(instance.TakenBy);
      this.changeInstanceOwnerRemote(instance);
    }
  }

  onTakeReturn(
    instance: BookInstanceWithStatus,
    update: Partial<BookInstanceWithStatus>
  ): void {
    const updatedInstance = {
      ...instance,
      ...update,
    } as BookInstanceWithStatus;
    if (instance.TakenBy !== updatedInstance.TakenBy) {
      if (instance.TakenBy !== null)
        this.props.callbacks.onReturn(instance.TakenBy);
      if (updatedInstance.TakenBy !== null)
        this.props.callbacks.onTake(updatedInstance.TakenBy);
    }
    const book = this.state.items.find((b) =>
      b.Instances.some(
        (i) => i.BookInstanceID === updatedInstance.BookInstanceID
      )
    );
    if (book) {
      this.changeInstanceOwnerRemote(updatedInstance);
      this.updateBook({
        ...book,
        Instances: book.Instances.map((i) =>
          i.BookInstanceID === updatedInstance.BookInstanceID
            ? updatedInstance
            : i
        ),
      });
    }
  }

  private changeInstanceOwnerRemote(updatedInstance: BookInstanceWithStatus) {
    fetchOrLogin(
      this,
      process.env.REACT_APP_BOOK_API_URL + "/rest/book_tx",
      "POST",
      JSON.stringify({
        item: {
          BookInstanceID: updatedInstance.BookInstanceID,
          TakenBy: updatedInstance.TakenBy,
          TransactionBy: this.props.page_state.user.UserID,
        },
      })
    );
  }

  private updateBook(updated: BookWithInstances) {
    const bookID = updated.Book.BookID;
    this.setState({
      items: this.state.items.map((b) =>
        b.Book.BookID === bookID ? updated : b
      ),
    });
  }
}

export default BookComponentList;
