import { useCallback } from "react";
// TODO: Replace with React built-in hook after upgrading to React 18:
import { useSyncExternalStore } from "use-sync-external-store/shim";

export default function useMediaQuery(query: string) {
  const subscribe = useCallback(
    (callback: () => any) => {
      const matchMedia = window.matchMedia(query);

      matchMedia.addEventListener("change", callback);

      return () => {
        matchMedia.removeEventListener("change", callback);
      };
    },
    [query]
  );

  const getSnapshot = () => {
    return window.matchMedia(query).matches;
  };

  return useSyncExternalStore(subscribe, getSnapshot);
}
