import "@rescui/colors/lib/index.css";

import { Button } from "@rescui/button";
import i18next from "i18next";
import React, { useEffect, useState } from "react";

import { bookCopyIcon, EditBookCopy, ViewBookCopy } from "./BookCopyComponent";
import { EditBookDetails } from "./EditBookDetails";
import {
  BookDetails,
  BookInstanceWithStatus,
  BookWithInstances,
  EditCallbacks,
  newBookCopy,
  PageState,
} from "./model";

export default function BookComponent(props: {
  book: BookWithInstances;
  instances: BookInstanceWithStatus[];
  pageState: PageState;
  callbacks: EditCallbacks;
}) {
  const book = props.book.Book;
  const [isExpanded, setExpanded] = useState(false);
  const [isEditing, setEditing] = useState(false);

  useEffect(() => setInstances(props.instances), [props.instances]);

  function stopEditing() {
    setEditing(false);
    setExpanded(true);
  }

  const [instances, setInstances] = useState(props.instances);
  const [editedDetails, setEditedDetails] = useState<BookDetails>(book);

  const editControls =
    props.pageState.user.UserRole !== "admin" ? null : !isEditing ? (
      <div>
        <Button mode="rock" size="xs" onClick={() => setEditing(true)}>
          {i18next.t("Edit...")}
        </Button>
        <br />
        <br />
      </div>
    ) : (
      <div>
        <Button
          mode="rock"
          size="xs"
          onClick={() => {
            props.callbacks.editBook({ ...book, ...editedDetails }, instances);
            stopEditing();
          }}
        >
          {i18next.t("Save changes")}
        </Button>
        &nbsp;
        <Button mode="rock" size="xs" onClick={() => stopEditing()}>
          {i18next.t("Cancel")}
        </Button>
        <br />
        <br />
        <Button
          mode="rock"
          size="xs"
          onClick={() => {
            const copy = instances.slice();
            copy.push(newBookCopy(props.pageState));
            setInstances(copy);
          }}
        >
          {i18next.t("Add a copy")}
        </Button>
      </div>
    );

  const waiting = props.book.WaitingList.filter(
    (e) => e.LibraryID === props.pageState.library.LibraryID
  );

  function joinWaitingListButton() {
    return (
      <Button
        mode="rock"
        size="xs"
        onClick={() =>
          props.callbacks.onJoinWaitingList(props.book, props.pageState.library)
        }
      >
        {i18next.t("Join waiting list")}
      </Button>
    );
  }

  function leaveWaitingListButton() {
    return (
      <Button
        mode="rock"
        size="xs"
        onClick={() =>
          props.callbacks.onLeaveWaitingList(
            props.book,
            props.pageState.library,
            props.book.WaitingList.filter(
              (e) => e.UserID === props.pageState.user.UserID
            )[0]
          )
        }
      >
        {i18next.t("Leave waiting list")}
      </Button>
    );
  }

  const waitingList = (
    <div className="waiting-list">
      {waiting.length > 0 && (
        <div>
          {i18next.t("Waiting list")}:&nbsp;
          {waiting
            .map((e) => props.pageState.allUsers.get(e.UserID)?.UserName || "?")
            .join(", ")}
        </div>
      )}
      {instances.every(
        (i) => i.TakenBy !== null && i.TakenBy !== props.pageState.user.UserID
      ) &&
        props.book.WaitingList.every(
          (e) => e.UserID !== props.pageState.user.UserID
        ) &&
        joinWaitingListButton()}
      {instances.every(
        (i) => i.TakenBy !== null && i.TakenBy !== props.pageState.user.UserID
      ) &&
        props.book.WaitingList.some(
          (e) => e.UserID === props.pageState.user.UserID
        ) &&
        leaveWaitingListButton()}
    </div>
  );

  function collapsedCopySection() {
    const takenByMe: BookInstanceWithStatus[] = [];
    const available: BookInstanceWithStatus[] = [];
    const notAvailable: BookInstanceWithStatus[] = [];

    instances.forEach((instance) => {
      (instance.TakenBy === null
        ? available
        : instance.TakenBy === props.pageState.user.UserID
        ? takenByMe
        : notAvailable
      ).push(instance);
    });

    // we want to display at most 5 icons
    const displayTakenByMe = takenByMe.slice(0, 5);
    const displayAvailable = available.slice(0, 5 - displayTakenByMe.length);
    const displayUnavailable = notAvailable.slice(
      0,
      5 - displayTakenByMe.length - displayAvailable.length
    );
    const displayMore = instances.length > 5;

    const userID = props.pageState.user.UserID;
    const button =
      takenByMe.length > 0 ? (
        <Button
          mode="rock"
          size="xs"
          onClick={() =>
            props.callbacks.onTakeReturn(takenByMe[0], { TakenBy: null })
          }
        >
          {i18next.t("Return")}
        </Button>
      ) : available.length > 0 ? (
        <Button
          mode="rock"
          size="xs"
          onClick={() =>
            props.callbacks.onTakeReturn(available[0], { TakenBy: userID })
          }
        >
          {i18next.t("Take")}
        </Button>
      ) : props.book.WaitingList.some((e) => e.UserID === userID) ? (
        leaveWaitingListButton()
      ) : (
        joinWaitingListButton()
      );

    return (
      <>
        <div>
          {displayTakenByMe
            .concat(displayAvailable, displayUnavailable)
            .map((i) => bookCopyIcon(i, userID))}
          {displayMore && <p key="more">...</p>}
        </div>
        {props.pageState.user.UserID ===
          props.pageState.selectedUser.UserID && (
          <div className="book-button">{button}</div>
        )}
      </>
    );
  }

  function copySection() {
    const available: BookInstanceWithStatus[] = [];
    const notAvailable: BookInstanceWithStatus[] = [];

    instances.forEach((instance) => {
      (instance.TakenBy === null ? available : notAvailable).push(instance);
    });

    const summary =
      available.length > 0
        ? i18next.t("{{count}} available", { count: available.length })
        : i18next.t("All {{count}} taken", { count: instances.length });

    return (
      <>
        {editControls}
        {!isEditing && <h4 className="mb-4">{summary}:</h4>}
        {available
          .concat(notAvailable)
          .map((instance, index) =>
            isEditing ? (
              <EditBookCopy
                key={instance.BookInstanceID}
                instance={instance}
                allUsers={props.pageState.allUsers}
                onChange={(updated) =>
                  setInstances(
                    instances.map((i, idx) => (idx === index ? updated : i))
                  )
                }
                pageState={props.pageState}
              />
            ) : (
              <ViewBookCopy
                key={instance.BookInstanceID}
                instance={instance}
                pageState={props.pageState}
                callbacks={props.callbacks}
              />
            )
          )}
        {!isEditing && waitingList}
      </>
    );
  }

  function viewBook() {
    const cover = book.Cover;
    const expandOnClick = (e: React.MouseEvent<HTMLDivElement>) => {
      if (e.target instanceof HTMLInputElement) return;

      const selection = window.getSelection();
      if (
        isExpanded &&
        selection &&
        selection.focusOffset !== selection.anchorOffset
      )
        return;

      setExpanded(!isExpanded);
    };
    return (
      <div className="view">
        {cover && (
          <div className="cover cursor-pointer" onClick={expandOnClick}>
            <img
              src={cover}
              alt={i18next.t("Book cover")}
              className="cover-image"
            />
          </div>
        )}
        <h3 className="m-0 title cursor-pointer" onClick={expandOnClick}>
          {book.Title}
        </h3>
        <div className="mb-4">{book.Authors.join(", ")}</div>
        <div
          className="description text-light cursor-pointer"
          onClick={expandOnClick}
        >
          <div
            className="body mb-8 text-light"
            dangerouslySetInnerHTML={{ __html: book.Description }}
          />
          {book.ISBNs.length > 0 && (
            <div className="isbn">
              {i18next.t("ISBN")}: {book.ISBNs.join(", ")}
            </div>
          )}
          {book.Links.length > 0 && (
            <>
              {i18next.t("Links")}:
              <ul className="links flex gap-4">
                {book.Links.map((link, index) => (
                  <li className="link" key={index}>
                    <a href={link}>{prettyLink(link)}</a>
                  </li>
                ))}
              </ul>
            </>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="book-entry" id={bookComponentId(book.BookID)}>
      <div
        className={
          "details flex gap-4" + (isExpanded || isEditing ? "" : " collapsed")
        }
      >
        {isEditing ? (
          <EditBookDetails
            forAdding={false}
            book={book}
            onChange={setEditedDetails}
          />
        ) : (
          viewBook()
        )}
        <div className="copy-list">
          {isEditing || isExpanded ? copySection() : collapsedCopySection()}
        </div>
      </div>
    </div>
  );
}

function prettyLink(link: string) {
  const start = link.indexOf("://");
  if (start < 0) return link;

  link = link.substring(start + 3);
  if (link.startsWith("www.")) link = link.substring(4);
  const slash = link.indexOf("/");
  return slash < 0 ? link : link.substring(0, slash + 1) + "...";
}

export function bookComponentId(bookId: number) {
  return "book_" + bookId;
}
