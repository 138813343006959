
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './index.p.css';

const sizeStyles = {
  "xs": styles.sizeXS,"s": styles.sizeS,"m": styles.sizeM,"l": styles.sizeL
}

export const Size = {
  "xs": "xs","s": "s","m": "m","l": "l"
};

const EmailIcon = React.forwardRef(
  ({ theme, size, className, ...props }, ref) => {return (<svg viewBox="0 0 24 24" {...props} className={cn(styles.icon, styles[theme], sizeStyles[size], className)} ref={ref}><path d="M4.804 6h14.4l-7.2 6-7.2-6zm-.247 13.33a2.089 2.089 0 01-.552-1.498V8l7.999 6.435L20.002 8v9.832a2.089 2.089 0 01-2 2.168H6.006a2.089 2.089 0 01-1.448-.67z" /></svg>)}
);

EmailIcon.propTypes = {
  className: PropTypes.string,
  theme: PropTypes.oneOf(["light", "dark"]),
  size: PropTypes.oneOf(["xs", "s", "m", "l"])
};

EmailIcon.defaultProps = {
  size: "m"
};

export default EmailIcon;