var styles = {
  "main": "_main_9e8ws0_24",
  "open": "_open_9e8ws0_36",
  "enabled": "_enabled_9e8ws0_41",
  "disabled": "_disabled_9e8ws0_45",
  "focused": "_focused_9e8ws0_49",
  "searchFocused": "_searchFocused_9e8ws0_50",
  "sizeL": "_sizeL_9e8ws0_56",
  "sizeM": "_sizeM_9e8ws0_59",
  "rs-reset-letter-spacing": "_rs-reset-letter-spacing_9e8ws0_1",
  "sizeS": "_sizeS_9e8ws0_62",
  "isMulti": "_isMulti_9e8ws0_136",
  "error": "_error_9e8ws0_69",
  "notSelected": "_notSelected_9e8ws0_144",
  "themeLight": "_themeLight_9e8ws0_148",
  "searchKeyFocused": "_searchKeyFocused_9e8ws0_154",
  "rock": "_rock_9e8ws0_168",
  "toggle": "_toggle_9e8ws0_173",
  "labelWrapper": "_labelWrapper_9e8ws0_177",
  "themeDark": "_themeDark_9e8ws0_199",
  "label": "_label_9e8ws0_177",
  "iconsWrapper": "_iconsWrapper_9e8ws0_285",
  "searchInput": "_searchInput_9e8ws0_340",
  "multi": "_multi_9e8ws0_366",
  "tagsContainer": "_tagsContainer_9e8ws0_375",
  "tag": "_tag_9e8ws0_375",
  "icon": "_icon_9e8ws0_285",
  "clearIcon": "_clearIcon_9e8ws0_490"
};
export { styles as default };
