
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './index.p.css';

const sizeStyles = {
  "xs": styles.sizeXS,"s": styles.sizeS,"m": styles.sizeM,"l": styles.sizeL
}

export const Size = {
  "xs": "xs","s": "s","m": "m","l": "l"
};

const TrashIcon = React.forwardRef(
  ({ theme, size, className, ...props }, ref) => {return (<svg viewBox="0 0 24 24" {...props} className={cn(styles.icon, styles[theme], sizeStyles[size], className)} ref={ref}><path d="M18.509 2l-3.495.809-6.805 1.574-3.501.81.449 1.948 13.801-3.193L18.509 2zM16 21H8a2.007 2.007 0 01-2-2.002V8.99h12V19a2.007 2.007 0 01-2 2z" /></svg>)}
);

TrashIcon.propTypes = {
  className: PropTypes.string,
  theme: PropTypes.oneOf(["light", "dark"]),
  size: PropTypes.oneOf(["xs", "s", "m", "l"])
};

TrashIcon.defaultProps = {
  size: "m"
};

export default TrashIcon;