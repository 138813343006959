var styles = {
  "wrapper": "_wrapper_134p28p_9",
  "tabsContainer": "_tabsContainer_134p28p_13",
  "indicator": "_indicator_134p28p_19",
  "light": "_light_134p28p_31",
  "classic": "_classic_134p28p_31",
  "dark": "_dark_134p28p_35",
  "rock": "_rock_134p28p_39",
  "tab": "_tab_134p28p_13",
  "sizeM": "_sizeM_134p28p_125",
  "rs-reset-letter-spacing": "_rs-reset-letter-spacing_134p28p_1",
  "sizeL": "_sizeL_134p28p_130",
  "innerOffsetM": "_innerOffsetM_134p28p_135",
  "innerOffsetL": "_innerOffsetL_134p28p_143",
  "short": "_short_134p28p_159",
  "selected": "_selected_134p28p_167",
  "icon": "_icon_134p28p_269",
  "iconLeft": "_iconLeft_134p28p_283",
  "iconRight": "_iconRight_134p28p_291"
};
export { styles as default };
