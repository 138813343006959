import { Button } from "@rescui/button";
import { Accordion, Collapse } from "@rescui/collapse";
import { EmailIcon, InfoIcon, LoadingIcon } from "@rescui/icons";
import { Input } from "@rescui/input";
import { useTextStyles } from "@rescui/typography";
import { endOfMonth, formatISO, isBefore, startOfMonth } from "date-fns";
import { KyResponse } from "ky";
import { useEffect, useState } from "react";
import useSWRMutation from "swr/mutation";

import type { User } from "../../auth/useAuthenticatedUser";
import useAuthenticatedUser from "../../auth/useAuthenticatedUser";
import fetch from "../../client/fetch";
import Footer from "../../layout/Footer";
import Header from "../../layout/Header";
import Layout from "../../layout/Layout";
import Main from "../../layout/Main";
import useDocumentTitle from "../../shared/useDocumentTitle";
import type { QueryParams, ReportSpec } from "./config";
import { reports } from "./config";

// TODO: Show success and error notifications

const formatDate = (date: Date) => formatISO(date, { representation: "date" });

const now = new Date();

const defaultStart = formatDate(startOfMonth(now));
const defaultEnd = formatDate(endOfMonth(now));

type RequestAction<T> = {
  trigger: () => Promise<T>;
  reset: () => void;
  error: Error | undefined;
  isLoading: boolean;
};

function identity<T>(x: T): T {
  return x;
}

function useReportRequest(
  report: ReportSpec,
  configure: (p: QueryParams) => QueryParams = identity
): RequestAction<KyResponse> {
  const { options, params = {} } = report;
  const url = `${report.url}?${new URLSearchParams(configure(params))}`;

  const action = useSWRMutation([url, options], ([...params]) =>
    fetch(...params)
  );

  return {
    isLoading: action.isMutating,
    trigger: action.trigger,
    error: action.error,
    reset: action.reset,
  };
}

function ReportRequestButton({ request }: { request: RequestAction<any> }) {
  return (
    <Button
      icon={request.isLoading ? <LoadingIcon /> : <EmailIcon />}
      disabled={request.isLoading}
      onClick={request.trigger}
      size="s"
    >
      Send to me
    </Button>
  );
}

function ReportEntry({ report }: { report: ReportSpec }) {
  const request = useReportRequest(report);

  return (
    <div className="flex flex-wrap gap-4 items-center justify-between p-2">
      <span className="flex-1"></span>
      <ReportRequestButton request={request} />
    </div>
  );
}

function DateRangeReportEntry({ report }: { report: ReportSpec }) {
  const [start, setStart] = useState(defaultStart);
  const [end, setEnd] = useState(defaultEnd);

  const request = useReportRequest(report, (params) => ({
    start,
    end,
    ...params,
  }));

  useEffect(() => {
    if (isBefore(end, start)) setEnd(start);
  }, [start, end]);

  return (
    <div className="flex flex-wrap gap-4 items-center justify-between p-2">
      <div className="flex flex-nowrap gap-2 items-center">
        <span>From</span>
        <Input
          size="s"
          type="date"
          name="start"
          value={start}
          onChange={(event) => setStart(event.target.value)}
        />
        <span>until</span>
        <Input
          size="s"
          type="date"
          name="end"
          value={end}
          onChange={(event) => setEnd(event.target.value)}
        />
      </div>
      <ReportRequestButton request={request} />
    </div>
  );
}

function UsageInfo({ user }: { user: User }) {
  const textCn = useTextStyles();
  return (
    <div className="flex items-center gap-1">
      <InfoIcon size="s" />
      <p className={textCn("rs-text-3")}>
        Reports will be sent to {user.email}. This may take a few minutes.
      </p>
    </div>
  );
}

export type Props = Record<string, never>;

function ReportList() {
  useDocumentTitle("JetID Reports");

  const user = useAuthenticatedUser();

  return (
    <Layout>
      <Header title="Reports" />
      <Main>
        <div className="flex flex-col gap-4">
          {user.isLoading ? (
            <LoadingIcon />
          ) : user.error ? (
            <div className="error">{user.error.message}</div>
          ) : (
            <>
              <UsageInfo user={user.data!} />
              <Accordion icon="arrow" gap={16}>
                {reports.map((report, index) => (
                  <Collapse key={index} title={report.name}>
                    {report.dates ? (
                      <DateRangeReportEntry report={report} />
                    ) : (
                      <ReportEntry report={report} />
                    )}
                  </Collapse>
                ))}
              </Accordion>
            </>
          )}
        </div>
      </Main>
      <Footer />
    </Layout>
  );
}

export default ReportList;
