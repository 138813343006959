import { Button } from "@rescui/button";
import { CheckIcon, ReadIcon, TrashIcon } from "@rescui/icons";
import { Input } from "@rescui/input";
import { Tooltip } from "@rescui/tooltip";
import { useTextStyles } from "@rescui/typography";
import clsx from "clsx";
import i18next from "i18next";

import {
  BookInstanceWithStatus,
  EditCallbacks,
  PageState,
  UserWithTakenCount,
} from "./model";
import { UserChooser } from "./UserChooser";

export function ViewBookCopy(props: {
  instance: BookInstanceWithStatus;
  pageState: PageState;
  callbacks: EditCallbacks;
}) {
  const textCn = useTextStyles();

  const user = props.pageState.user;
  const instance = props.instance;

  return (
    <div className={clsx("flex gap-4 items-center mb-4", textCn("rs-text-3"))}>
      <div>{bookCopyIcon(instance, user.UserID)}</div>

      <div>
        {instance.Label && `${instance.Label}:`}

        {instance.TakenBy ? (
          <>
            Now read by{" "}
            <strong>
              {props.pageState.allUsers.get(instance.TakenBy)?.UserName || "?"}
            </strong>
          </>
        ) : (
          "Available"
        )}
      </div>

      {instance.TakenBy === null ? (
        <Button
          mode="rock"
          size="xs"
          onClick={() =>
            props.callbacks.onTakeReturn(instance, { TakenBy: user.UserID })
          }
        >
          {i18next.t("Take")}
        </Button>
      ) : user.UserID === instance.TakenBy ? (
        <Button
          mode="rock"
          size="xs"
          onClick={() =>
            props.callbacks.onTakeReturn(instance, { TakenBy: null })
          }
        >
          {i18next.t("Return")}
        </Button>
      ) : null}
    </div>
  );
}

export function EditBookCopy(props: {
  instance: BookInstanceWithStatus;
  allUsers: Map<number, UserWithTakenCount>;
  onChange: (updated: BookInstanceWithStatus) => void;
  pageState: PageState;
}) {
  const instance = props.instance;
  let currentInstance = instance;

  return (
    <div className="edit-copy">
      <p />
      <div>
        {i18next.t("Label")}:
        <Input
          mode="rock"
          type="text"
          defaultValue={instance.Label}
          onChange={(e) => {
            currentInstance = { ...currentInstance, Label: e.target.value };
            props.onChange(currentInstance);
          }}
        />
      </div>
      <div>
        {i18next.t("Now read by")}:
        <UserChooser
          allUsers={props.allUsers}
          selected={
            instance.TakenBy
              ? props.allUsers.get(instance.TakenBy) || null
              : null
          }
          renderItem={(u) =>
            u === null ? i18next.t("Nobody") : u.UserName || "?"
          }
          showNullUser={true}
          libraryId={props.pageState.library.LibraryID}
          onChosen={(user) => {
            currentInstance = {
              ...currentInstance,
              TakenBy: user?.UserID || null,
            };
            props.onChange(currentInstance);
          }}
        />
      </div>
      <div>
        {!currentInstance.WrittenOffBy ? (
          <Button
            mode="rock"
            size="xs"
            icon={<TrashIcon />}
            onClick={() => {
              currentInstance = {
                ...currentInstance,
                WrittenOffBy: props.pageState.user.UserID,
              };
              props.onChange(currentInstance);
            }}
          >
            {i18next.t("Write off")}
          </Button>
        ) : (
          <Button
            mode="outline"
            size="xs"
            onClick={() => {
              currentInstance = { ...currentInstance, WrittenOffBy: null };
              props.onChange(currentInstance);
            }}
          >
            {i18next.t("Cancel writing off")}
          </Button>
        )}
      </div>
    </div>
  );
}

export function bookCopyIcon(i: BookInstanceWithStatus, userID: number) {
  const tooltip_text = i.WrittenOffBy
    ? "Written off"
    : i.TakenBy === null
    ? i18next.t("Available")
    : i.TakenBy === userID
    ? i18next.t("Taken by me")
    : i18next.t("Taken");
  return (
    <Tooltip
      sparse={false}
      placement="top-end"
      content={i.Label + ": " + tooltip_text}
    >
      {i.WrittenOffBy
        ? writtenOffIcon(i)
        : i.TakenBy === null
        ? availableIcon(i)
        : i.TakenBy === userID
        ? takenByMeIcon(i)
        : unavailableIcon(i)}
    </Tooltip>
  );
}

function takenByMeIcon(i: BookInstanceWithStatus) {
  return (
    <CheckIcon key={i.BookInstanceID} mode="rock" size="l" color="black" />
  );
}

function availableIcon(i: BookInstanceWithStatus) {
  return (
    <ReadIcon key={i.BookInstanceID} mode="rock" size="l" color="#4DBB5F" />
  );
}

function unavailableIcon(i: BookInstanceWithStatus) {
  return (
    <ReadIcon key={i.BookInstanceID} mode="rock" size="l" color="#F3C033" />
  );
}

function writtenOffIcon(i: BookInstanceWithStatus) {
  return (
    <ReadIcon key={i.BookInstanceID} mode="rock" size="l" color="#BABABB" />
  );
}
