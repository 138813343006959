var styles = {
  "wrapper": "_wrapper_imuce_8",
  "menuItem": "_menuItem_imuce_12",
  "content": "_content_imuce_40",
  "placementRight": "_placementRight_imuce_46",
  "sizeS": "_sizeS_imuce_51",
  "rs-reset-letter-spacing": "_rs-reset-letter-spacing_imuce_1",
  "sizeM": "_sizeM_imuce_56",
  "sizeL": "_sizeL_imuce_61",
  "modeClear": "_modeClear_imuce_66",
  "themeLight": "_themeLight_imuce_73",
  "themeDark": "_themeDark_imuce_78",
  "keyboardNavigationDisabled": "_keyboardNavigationDisabled_imuce_83",
  "modeClassic": "_modeClassic_imuce_99",
  "selected": "_selected_imuce_111",
  "modeRock": "_modeRock_imuce_121",
  "disableCursor": "_disableCursor_imuce_141",
  "highlighted": "_highlighted_imuce_146",
  "disabled": "_disabled_imuce_229",
  "icon": "_icon_imuce_240",
  "placementLeft": "_placementLeft_imuce_244",
  "divider": "_divider_imuce_270",
  "groupHeader": "_groupHeader_imuce_288",
  "groupHeaderSizeL": "_groupHeaderSizeL_imuce_292",
  "groupHeaderSizeM": "_groupHeaderSizeM_imuce_297",
  "groupHeaderSizeS": "_groupHeaderSizeS_imuce_302",
  "groupHeaderThemeLight": "_groupHeaderThemeLight_imuce_307",
  "groupHeaderThemeDark": "_groupHeaderThemeDark_imuce_311"
};
export { styles as default };
