var styles = {
  "wrapper": "_wrapper_srqts_10",
  "tag": "_tag_srqts_14",
  "alignTop": "_alignTop_srqts_20",
  "sizeXs": "_sizeXs_srqts_21",
  "sizeS": "_sizeS_srqts_24",
  "sizeM": "_sizeM_srqts_27",
  "sizeL": "_sizeL_srqts_30",
  "alignMiddle": "_alignMiddle_srqts_35",
  "top": "_top_srqts_52",
  "start": "_start_srqts_56",
  "offset16": "_offset16_srqts_57",
  "offset24": "_offset24_srqts_60",
  "offset32": "_offset32_srqts_63",
  "end": "_end_srqts_68",
  "left": "_left_srqts_90"
};
export { styles as default };
