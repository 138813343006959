{
  "Add this book": "Add this book",
  "Cancel": "Cancel",
  "Add another copy": "Add another copy",
  "Edit...": "Edit...",
  "Save changes": "Save changes",
  "Add a copy": "Add a copy",
  "Join waiting list": "Join waiting list",
  "Leave waiting list": "Leave waiting list",
  "Waiting list": "Waiting list",
  "Return": "Return",
  "Take": "Take",
  "{{count}} available": "{{count}} available",
  "{{count}} available_plural": "{{count}} available",
  "All {{count}} taken": "All {{count}} taken",
  "All {{count}} taken_plural": "All {{count}} taken",
  "Book cover": "Book cover",
  "ISBN": "ISBN",
  "Links": "Links",
  "Didn't find what you were looking for? <br/> Use {{- YouTrack}} to order the book you need.": "Didn't find what you were looking for? <br/> Use {{- YouTrack}} to order the book you need.",
  "Load more": "Load more",
  "Label": "Label",
  "Now read by": "Now read by",
  "Nobody": "Nobody",
  "Write off": "Write off",
  "Cancel writing off": "Cancel writing off",
  "Available": "Available",
  "Taken by me": "Taken by me",
  "Taken": "Taken",
  "Links (comma-separated)": "Links (comma-separated)",
  "Book title": "Book title",
  "Book authors (comma-separated)": "Book authors (comma-separated)",
  "Cover image URL": "Cover image URL",
  "Book description (HTML allowed)": "Book description (HTML allowed)",
  "ISBNs (comma-separated)": "ISBNs (comma-separated)",
  "Performing first-time setup for {{email}}...": "Performing first-time setup for {{email}}...",
  "Welcome to JetID Book, {{user}}!": "Welcome to JetID Book, {{user}}!",
  "My books ({{count}})": "My books ({{count}})",
  "My books ({{count}})_plural": "My books ({{count}})",
  "Books of {{name}} ({{count}})": "Books of {{name}} ({{count}})",
  "Books of {{name}} ({{count}})_plural": "Books of {{name}} ({{count}})",
  "My waiting list ({{count}})": "My waiting list ({{count}})",
  "My waiting list ({{count}})_plural": "My waiting list ({{count}})",
  "Waiting list of {{name}} ({{count}})": "Waiting list of {{name}} ({{count}})",
  "Waiting list of {{name}} ({{count}})_plural": "Waiting list of {{name}} ({{count}})",
  "Change User": "Change User",
  "Library": "Library",
  "Search books by title, authors, description": "Search books by title, authors, description",
  "Add a book": "Add a book",
  "Local Users": "Local Users",
  "Other Users": "Other Users"
}
