var styles = {
  "wrapper": "_wrapper_4e3oje_36",
  "sizeXs": "_sizeXs_4e3oje_48",
  "sizeS": "_sizeS_4e3oje_49",
  "rs-reset-letter-spacing": "_rs-reset-letter-spacing_4e3oje_1",
  "sizeM": "_sizeM_4e3oje_53",
  "sizeL": "_sizeL_4e3oje_57",
  "themeLight": "_themeLight_4e3oje_61",
  "disabled": "_disabled_4e3oje_65",
  "themeDark": "_themeDark_4e3oje_69",
  "main": "_main_4e3oje_110",
  "wrap": "_wrap_4e3oje_36",
  "close": "_close_4e3oje_146",
  "icon": "_icon_4e3oje_243"
};
export { styles as default };
