var styles = {
  "main": "_main_1uoszgd_25",
  "rs-reset-letter-spacing": "_rs-reset-letter-spacing_1uoszgd_1",
  "alignIconLeft": "_alignIconLeft_1uoszgd_46",
  "alignIconRight": "_alignIconRight_1uoszgd_50",
  "sizeXs": "_sizeXs_1uoszgd_54",
  "sizeS": "_sizeS_1uoszgd_61",
  "sizeM": "_sizeM_1uoszgd_68",
  "sizeL": "_sizeL_1uoszgd_75",
  "icon": "_icon_1uoszgd_82",
  "uppercase": "_uppercase_1uoszgd_116"
};
export { styles as default };
