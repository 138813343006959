import { useTheme } from "@rescui/ui-contexts";
import type { ReactNode } from "react";

export type Props = { children: ReactNode };

function Screen({ children }: Props) {
  const theme = useTheme();

  const style =
    theme === "dark"
      ? {
          background: "var(--rs-color-grey-90)",
          color: "var(--rs-color-white-t70)",
        }
      : {
          background: "var(--rs-color-white)",
          color: "var(--rs-color-black-t70)",
        };

  return (
    <div className="screen" style={style}>
      {children}
    </div>
  );
}

export default Screen;
