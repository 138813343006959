import { LoadingIcon } from "@rescui/icons";
import React from "react";

import useAuthenticatedUser from "../../auth/useAuthenticatedUser";
import Footer from "../../layout/Footer";
import Header from "../../layout/Header";
import Layout from "../../layout/Layout";
import Main from "../../layout/Main";
import Link from "../../shared/Link";
import useDocumentTitle from "../../shared/useDocumentTitle";

export type Props = Record<string, never>;

function Landing() {
  useDocumentTitle("JetID");

  const user = useAuthenticatedUser();

  return (
    <Layout>
      <Header title="Internal" />
      <Main>
        <ul className="flex flex-col gap-4">
          {user.isLoading ? (
            <li>
              <LoadingIcon />
            </li>
          ) : (
            <>
              <li>
                <Link to={{ pathname: "/timesheet" }}>Fill timesheet</Link>
              </li>
              <li>
                <Link to={{ pathname: "/reports" }}>Reports</Link>
              </li>
            </>
          )}
        </ul>
      </Main>
      <Footer />
    </Layout>
  );
}

export default Landing;
