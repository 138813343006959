
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './index.p.css';

const sizeStyles = {
  "xs": styles.sizeXS,"s": styles.sizeS,"m": styles.sizeM,"l": styles.sizeL
}

export const Size = {
  "xs": "xs","s": "s","m": "m","l": "l"
};

const ReadIcon = React.forwardRef(
  ({ theme, size, className, ...props }, ref) => {return (<svg viewBox="0 0 24 24" {...props} className={cn(styles.icon, styles[theme], sizeStyles[size], className)} ref={ref}><path d="M13.999 5a2 2 0 11-4 0 2 2 0 014 0zm4.348 3h2.656l.033 9.967h-2.988A6.624 6.624 0 0012.02 22a6.913 6.913 0 00-6.25-4H3.036V8H5.77a6.912 6.912 0 016.25 4 7.025 7.025 0 016.327-4z" /></svg>)}
);

ReadIcon.propTypes = {
  className: PropTypes.string,
  theme: PropTypes.oneOf(["light", "dark"]),
  size: PropTypes.oneOf(["xs", "s", "m", "l"])
};

ReadIcon.defaultProps = {
  size: "m"
};

export default ReadIcon;