import { useEffect } from "react";
import useSWR from "swr";

import { HTTPError } from "../client/errors";
import fetch from "../client/fetch";
import { login } from "../Util/common";

export type User = { email: string };

export default function useAuthenticatedUser() {
  const user = useSWR("user", async function user(): Promise<User> {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/who-am-i`, {
      method: "POST",
      credentials: "include",
    });

    return response.json();
  });

  useEffect(() => {
    if (!(user.error instanceof HTTPError)) return;
    if (![401, 403].includes(user.error.response.status)) return;
    login();
  }, [user.error]);

  return user;
}
