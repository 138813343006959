var styles = {
  "wrapper": "_wrapper_vrgn9m_4",
  "scrollable": "_scrollable_vrgn9m_10",
  "arrow": "_arrow_vrgn9m_39",
  "light": "_light_vrgn9m_58",
  "dark": "_dark_vrgn9m_61",
  "shown": "_shown_vrgn9m_65",
  "arrowLeft": "_arrowLeft_vrgn9m_73",
  "arrowRight": "_arrowRight_vrgn9m_78",
  "withLeftArrow": "_withLeftArrow_vrgn9m_84",
  "withRightArrow": "_withRightArrow_vrgn9m_93",
  "arrowButton": "_arrowButton_vrgn9m_114"
};
export { styles as default };
