import type { Options } from "../../client/fetch";

const api = (process.env.REACT_APP_API_URL ?? "").replace(/\/$/, "");

const url = (...path: string[]) => [api, ...path].join("/");

const defaults: Options = {
  method: "POST",
  credentials: "include",
  timeout: 3000,
};

export type QueryParams = Record<string, string>;

export type ReportSpec<Params extends QueryParams = QueryParams> = {
  name: string;
  url: string;
  params?: Params;
  options: Options;
  dates?: boolean;
};

export type FitnessReportSpec = ReportSpec<{ location: string }>;
export type MailingListReportSpec = ReportSpec<{ business_entity_id: string }>;
export type MassageReportSpec = ReportSpec<{ site: string }>;
export type SiteReportSpec = ReportSpec<{ site: string }>;

const Spec = {
  fitnessReport: (name: string, location: string): FitnessReportSpec => ({
    name: `Fitness Report (${name})`,
    url: url("reports", "fitness"),
    params: { location },
    options: defaults,
    dates: true,
  }),
  mailingListReport: (name: string, id: string): MailingListReportSpec => ({
    name: `Mailing List (${name})`,
    url: url("reports", "mailing-list"),
    params: { business_entity_id: id },
    options: defaults,
  }),
  massageReport: (name: string, site: string): MassageReportSpec => ({
    name: `Massage Report (${name})`,
    url: url("reports", "massage"),
    params: { site },
    options: defaults,
    dates: true,
  }),
  siteReport: (name: string, site: string): SiteReportSpec => ({
    name,
    url: url("reports", "site"),
    params: { site },
    options: defaults,
    dates: true,
  }),
};

export const reports: ReportSpec[] = [
  Spec.siteReport("Armenia", "am"),
  Spec.siteReport("China", "cn"),
  Spec.siteReport("Cyprus", "cy"),
  Spec.siteReport("Czech Republic", "cz"),
  Spec.siteReport("Germany (Berlin)", "de-berlin"),
  Spec.siteReport("Germany (Munich)", "de-munich"),
  Spec.siteReport("Germany (Remote)", "de-remote"),
  Spec.siteReport("Israel", "il"),
  Spec.siteReport("Netherlands", "nl"),
  Spec.siteReport("Poland", "pl"),
  Spec.siteReport("Serbia", "rs"),
  Spec.siteReport("UAE", "uae"),
  Spec.siteReport("US (Boston)", "us-boston"),
  Spec.mailingListReport("JetBrains GmbH", "2ASECl0IKfj8"),
  Spec.fitnessReport("Munich", "de-munich"),
  Spec.massageReport("Munich", "de-munich"),
];
